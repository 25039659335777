import { FC } from "react";

import { ILocales } from "./i18n";

import "./maintenance.css";

interface IProps {
  messages: ILocales;
}

const Maintenance: FC<IProps> = ({ messages }) => (
  <div className="maintenance">
    <article>
      <h1>We&rsquo;ll be back soon!</h1>
      <div>
        <p>
          Sorry for the inconvenience but we&rsquo;re performing some
          maintenance at the moment. We&rsquo;ll be back online shortly!
        </p>
        <p>&mdash; PixelData Team</p>
      </div>
    </article>
  </div>
);

export default Maintenance;
